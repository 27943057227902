<template>
  <div class="customerView">
    <van-nav-bar title="联系客服" left-arrow @click-left="onClickLeft" />
    <div class="hz_form" v-if="Object.keys(detail).length !== 0">
      <van-tabs v-model="active">
        <van-tab title="微信二维码">
          <div class="pay_content_box">
            <van-cell-group>
              <div class="qrcode">
                <div class="img">
                  <img :src="$imageUrl + detail.wechat" />
                </div>
              </div>
            </van-cell-group>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { getKefuInfo } from '@/api/users';
export default {
  data() {
    return {
      active: 0,
      detail: {},
    };
  },
  created() {
    this.GetKefuInfo();
  },

  methods: {
    GetKefuInfo() {
      getKefuInfo({})
        .then((response) => {
          this.detail = response.result;
        })
        .catch((error) => {});
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="stylus">
.customerView
  .hz_form
    padding: 0 0.4rem 0.25rem
    .van-tabs
      margin-top: 0.3rem
      .pay_content_box
        background-color: $themeColor
        .qrcode
          display: flex
          padding: 0.26667rem 0.42667rem
          .img
            flex: 1
            text-align: center
            img
              width: 8rem
          .title
            width: 2.4rem
    .buy_fabu_mod
      background: $themeColor
      margin-top: 0.33rem
      border-radius: 0.1rem
      overflow: hidden
      box-shadow: 0px 0px 15px #303046
      padding: 0 0.5rem 0 0.5rem
      .checkin_notice
        padding: 0.3rem 0
        .checkin_notice_header
          margin-top: 0.3rem
          display: flex
          font-size: 0.42rem
          text-align: center
          line-height: 0.42rem
          margin-bottom: 0.2rem
          font-weight: 700
          .left
            flex: 1
            text-align: left
            .upload_title
              font-size: 0.36rem
              color: #fff
            .van-uploader
              margin-top: 0.3rem
              .van-uploader__upload
                background-color: #12132a
                border: 1px dashed #735f5f
        .checkin_notice_content
          font-size: 0.36rem
          color: $grayTextColor
          line-height: 0.4rem
          letter-spacing: 0.015rem
      .btn
        width: 90%
        margin: 0.5rem auto
</style>
